import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpWithIndividualOnboardingByAccountantInviteEmail = () => {
  return (
    <Layout>
      <Seo
        title="How to Sign Up with Individual Onboarding by Accountant Invite Email"
        description={`Learn how to Sign Up with Individual Sign up via Accountant Invite`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-with-individual-onboarding-by-accountant-invite-email`} />
    </Layout>
  )
}

export default SignUpWithIndividualOnboardingByAccountantInviteEmail
